
// Styles
import "./styles.scss";

const ComingSoon = () => {
  return (
    <div className="main-container">
      <div className="heading-section">
        <h1 className="title">
          Elevation <span className="sub-title">Holdings</span>
        </h1>
      </div>
      <div className="footer">
        <div className="copyright-text"> &copy; 2021. All rights reserved.</div>
      </div>
    </div>
  );
};

export default ComingSoon;
